html,
body {
    height: 100%;
}

body {
    color               : black;
    background-color    : whitesmoke;
    font-family         : "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
    //font-size         : 14px !important;
    //color           : #484848;
}

// nb-card {
//     background-color: #eae1d3;
//     border-radius   : 0;
//     //border          : 1.5px solid #d6ae73 !important;
//     border          : 1.5px solid #cfbda2 !important;
// }

// nb-card-header {
//     font-weight     : bold !important;
//     background-color: #eae1d3;
//     border-radius   : 0;
//     border-bottom   : 1.5px solid #cfbda2 !important;
//     //border-bottom   : 1.5px solid #d6ae73 !important;
// }

// nb-accordion {
//     background-color: #eae1d3;
//     border-radius   : 0;
//     border          : 1.5px solid #cfbda2 !important;
//     //border          : 1.5px solid #d6ae73 !important;
// }

// nb-accordion-item-header {
//     font-weight     : bold !important;
//     height          : 55px;
//     background-color: #eae1d3;
//     border-radius   : 0;
//     border-bottom   : 1.5px solid #cfbda2 !important;
//     //border-bottom   : 1.5px solid #d6ae73 !important;
// }

// button.btn {
//     min-width: 120px;
//     text-transform: capitalize !important;
// }

// :host ::ng-deep .p-datatable .p-datatable-thead > tr > th{
//     background: #eae1d3 !important;
//     border   : 1.5px solid #d6ae73 !important;
// }

nb-card-header {
    font-size  : 15px;
    font-weight: bold !important;
}

nb-card-body {
    font-size: 15px;
}

nb-accordion-item-header {
    font-size  : 15px;
    font-weight: bold !important;
}

p-dropdown.ng-invalid:not(form)>div {
    border: 1px solid #D40000;
}

p-dropdown.ng-invalid.ng-touched.ui-inputwrapper-focus:not(form)>div {
    box-shadow   : 0 0 0 0.05em #F40000;
    border       : 1px solid #D40000;
    border-radius: 2px;
}

p-dropdown.ng-invalid.ng-untouched.ui-inputwrapper-focus:not(form)>div {
    box-shadow   : 0 0 0 0.05em #F40000;
    border       : 1px solid #D40000;
    border-radius: 2px;
}

p-inputnumber.ng-invalid:not(form)>div {
    border: 1px solid #D40000;
}

p-inputnumber.ng-invalid.ng-touched.ui-inputwrapper-focus:not(form)>div {
    box-shadow   : 0 0 0 0.05em #F40000;
    border       : 1px solid #D40000;
    border-radius: 2px;
}

p-inputnumber.ng-invalid.ng-untouched.ui-inputwrapper-focus:not(form)>div {
    box-shadow   : 0 0 0 0.05em #F40000;
    border       : 1px solid #D40000;
    border-radius: 2px;
}

p-multiSelect.ng-invalid:not(form)>div {
    border: 1px solid #D40000;
}

p-multiSelect.ng-invalid.ng-touched.ui-inputwrapper-focus:not(form)>div {
    box-shadow   : 0 0 0 0.05em #F40000;
    border       : 1px solid #D40000;
    border-radius: 2px;
}

p-multiSelect.ng-invalid.ng-untouched.ui-inputwrapper-focus:not(form)>div {
    box-shadow   : 0 0 0 0.05em #F40000;
    border       : 1px solid #D40000;
    border-radius: 2px;
}

ngx-spinner {
    .la-ball-clip-rotate.la-2x>div {
        border-width: 6px;
    }
}

nb-auth nb-card-header {
    display: none !important;
}

p-accordion .p-accordion-header .p-accordion-header-link {
    text-decoration: none !important;
}

.btnPDF {
    float        : right;
    margin-right : 0.5em;
    background   : none;
    cursor       : pointer;
    position     : relative;
    bottom       : 0.19em;
}

nb-tooltip {
    white-space: pre-line !important;
}